import { reactive, toRefs, inject } from "vue";
import validationAlert from "@/utils/reusables/SwalAlert";
export default function applicationData() {
  const axios = inject("$axios");
  const store = inject("$store");
  const moment = inject("$moment");
  const router = inject("$router");
  const Swal = inject("$swal");
  const state = reactive({
    agentInfo: [],
    planInfo: [],
    loading: false,
    countdown: 60,
    qrLoading: false,
    isRender: true,
  });
  const { errorHandler } = validationAlert();
  const fetchAgentItems = async (val) => {
    try {
      let agentcode = val;
      const { data } = await axios.get(`/meta/api/agents`, {
        params: {
          agentcode: agentcode,
        },
      });
      return data;
    } catch (error) {
      errorHandler(error);
    }
  };
  const fetchPlanItems = async (val) => {
    try {
      let plancode = val;
      const { data } = await axios.get(`/meta/api/plans/`, {
        params: {
          plancode: plancode,
        },
      });
      return data;
    } catch (error) {
      errorHandler(error);
    }
  };
  const createdClients = async (payment, amountInput, paymentMethodid) => {
    try {
      /*const timer = setInterval(() => {
        state.countdown--;
        if (state.countdown === 0) {
          clearInterval(timer);
          state.loading = false;
          state.countdown = 60; // Reset countdown
        }
      }, 1000); */

      let paymentmethodid = paymentMethodid;
      let transactionID;
      store.state.formData.paymentmethod = payment;
      let formDetails = store.state.formData;

      if (!store.state.ifRetrievedData) {
        const formData = new FormData();
        formData.append(
          "file",
          store.state.formData.uploadFile,
          store.state.formData.ModFilename
        );
        let result = await axios.post(`/meta/api/local/files`, formData);
        if (result.status === 200) {
          const { data } = await axios.post("/meta/api/clients", {
            agentcode: formDetails.agentcode,
            plantypeid: formDetails.plantypeid,
            premium: formDetails.premium,
            payorname: formDetails.payorname,
            relationship: formDetails.relationship,
            membername: null,
            lastname: formDetails.lastname,
            firstname: formDetails.firstname,
            middlename: formDetails.middlename,
            suffix: formDetails.suffix,
            birthdate: formDetails.birthdate,
            civilstatus: formDetails.civilstatus,
            contactnumber: formDetails.contactnumber,
            email: formDetails.email,
            homeaddressOne: formDetails.homeaddressOne,
            homeaddressTwo: formDetails.homeaddressTwo,
            paymentmethod: formDetails.paymentmethod,
            paymentmethodid: paymentmethodid,
            status: "pending",
          });
          if (data.status) {
            state.loading = false;
            transactionID = data.data.id;
            Swal.fire({
              position: "bottom",
              icon: "success",
              background: "var(--primary-color)",
              color: "var(--light-color)",
              title:
                amountInput === 0
                  ? "Zero payment indicates that you did not pay any amount to your agent and your application is pending."
                  : "Your application is being processed.",
              showConfirmButton: true,
              allowOutsideClick: false,
              confirmButtonColor: "var(--secondary-color)",
            }).then((result) => {
              if (result.isConfirmed) {
                if (amountInput) {
                  return router.push({
                    path: `/deped/alert/transaction/temp/meta/api/raw/${transactionID}`,
                    query: {
                      paymentMethod: store.state.formData.paymentmethod,
                    },
                  });
                } else {
                  return router.push(
                    `/deped/alert/transaction/temp/meta/api/raw/hold/${data.data.referenceNumber}`
                  );
                }
              }
            });
          }
        }
      } else {
        Swal.fire({
          position: "bottom",
          icon: "success",
          background: "var(--primary-color)",
          color: "var(--light-color)",
          title:
            "We are processing your application, Once payment is processed, click OK.",
          showConfirmButton: true,
          allowOutsideClick: false,
          confirmButtonColor: "var(--secondary-color)",
        }).then((result) => {
          if (result.isConfirmed) {
            if (amountInput) {
              let transactionID = store.state.transactionId;
              return router.push(
                `/deped/alert/transaction/temp/meta/api/raw/${transactionID}`
              );
            }
          }
        });
      }
    } catch (error) {
      state.loading = false;
      errorHandler(error);
    }
  };
  const fetchAgentDataFile = async (filename) => {
    try {
      let name = filename;
      return await axios.get(`meta/api/local/files/agent/${name}`);
    } catch (error) {
      errorHandler(error);
    }
  };
  const fetchValueAddedBenefit = async (filename) => {
    try {
      let name = filename;
      return await axios.get(`meta/api/local/files/temp/${name}`);
    } catch (error) {
      errorHandler(error);
    }
  };
  const OnlinePaymentProcess = async (reference) => {
    try {
      const { data } = await axios.post("https://api.xendit.co/v2/invoices", {
        external_id: `IMS-${reference}`,
        amount: store.state.formData.premium,
        description: "IMS DEPED",
        capture: false,
        invoice_duration: 86400,
        customer: {
          given_names: store.state.formData.firstname,
          surname: store.state.formData.lastname,
          email: store.state.formData.email,
          mobile_number: store.state.formData.contactnumber,
          addresses: [
            {
              city: store.state.formData.homeaddressOne,
              country: "Philippines",
            },
          ],
        },
        success_redirect_url: `${process.env.VUE_APP_BASE_URL}#/deped/alert/transaction/temp/meta/api/raw/releasing/${reference}`,

        failure_redirect_url: process.env.VUE_APP_BASE_URL,
        currency: "PHP",
        items: [
          {
            name: "Teacher Plan",
            quantity: 1,
            price: store.state.formData.premium,
            category: "Healthcard",
            url: "https://imsdeped.anec.global",
          },
        ],
        payment_methods: ["CREDIT_CARD", "GCASH", "PAYMAYA"],
      });
      window.open(data.invoice_url, "_self");
    } catch (error) {
      errorHandler(error);
    }
  };
  const createdClientsOnline = async (reference) => {
    try {
      let paymentmethodid = 2;
      let transactionID;
      store.state.formData.paymentmethod = "Online Payment";
      let formDetails = store.state.formData;

      const { data } = await axios.post("/meta/api/clients", {
        agentcode: formDetails.agentcode,
        plantypeid: formDetails.plantypeid,
        premium: formDetails.premium,
        payorname: formDetails.payorname,
        relationship: formDetails.relationship,
        membername: null,
        lastname: formDetails.lastname,
        firstname: formDetails.firstname,
        middlename: formDetails.middlename,
        suffix: formDetails.suffix,
        birthdate: formDetails.birthdate,
        civilstatus: formDetails.civilstatus,
        contactnumber: formDetails.contactnumber,
        email: formDetails.email,
        homeaddressOne: formDetails.homeaddressOne,
        homeaddressTwo: formDetails.homeaddressTwo,
        paymentmethod: formDetails.paymentmethod,
        paymentmethodid: paymentmethodid,
        status: "paid",
      });
      transactionID = data.data.id;

      //insert

      updateTransactionOnline(transactionID, reference);
    } catch (error) {
      console.log(error);
      state.loading = false;
      errorHandler(error);
    }
  };
  const updateTransactionOnline = async (id, reference) => {
    try {
      let result = await axios.post(`/meta/api/auth/production?id=${id}`);
      if (result) {
        let res = await axios.put(`/meta/api/clients/${id}`, {
          status: "complete",
          reference: reference,
          datepayment: moment(),
        });
        if (res.data.status) {
          state.isRender = false;
          Swal.fire({
            title: "Your payment was completed successfully",
            text: "Check your email for your virtual card. Thank you!",
            icon: "success",
            showConfirmButton: true,
            allowOutsideClick: false,
            background: "var(--primary-color)",
            color: "var(--light-color)",
            confirmButtonColor: "var(--secondary-color)",
          }).then((result) => {
            if (result.isConfirmed) {
              store.commit("NewApplicationHandler");
              //localStorage.clear();
              store.commit("resetState");
              return router.push(`/`);
            }
          });
        }
      }
    } catch (error) {
      errorHandler(error);
    }
  };
  return {
    ...toRefs(state),
    fetchAgentItems,
    fetchPlanItems,
    createdClients,
    fetchAgentDataFile,
    fetchValueAddedBenefit,
    OnlinePaymentProcess,
    createdClientsOnline,
    updateTransactionOnline,
    errorHandler,
  };
}
