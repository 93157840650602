import { createRouter, createWebHashHistory } from "vue-router";
import LandingPage from "../pages/LandingIndex.vue";
import QRScannerPage from "../pages/QRScannerIndex.vue";
import ApplicationPage from "../pages/ApplicationIndex.vue";
import CompletedPage from "../pages/CompletedIndex.vue";
import OnlinePayment from "../pages/OnlineCompletedIndex.vue";
import HoldPaymentPage from "../pages/HoldPaymentIndex.vue";
import RetrievePage from "../pages/RetrieveIndex.vue";
import CategoryOptionPage from "../pages/OptionCategoryIndex.vue";
import ManualAgentCodePage from "../pages/ManualAgentCodeIndex.vue";
import AuthLoginPage from "../pages/portal/AuthLoginIndex.vue";
import AgentProfilePage from "../pages/portal/AgentProfileIndex.vue";
import AgentSettingPage from "../pages/portal/AgentSettingIndex.vue";
import AgentChangePasswordPage from "../pages/portal/AgentSettingChangePasswordIndex.vue";
import AgentSettingProfilePage from "../pages/portal/AgentSettingProfileIndex.vue";
import AgentSettingAboutPage from "../pages/portal/AgentSettingAboutIndex.vue";
import AgentSettingStatisticPage from "../pages/portal/AgentSettingStatisticIndex.vue";
import AgentPortfolioPage from "../pages/portal/AgentPortfolioIndex.vue";
import AgentPortfolioClientProfilePage from "../pages/portal/AgentPortfolioClientProfileIndex.vue";
import AgentPortfolioClientPage from "../pages/portal/AgentPortfolioClientIndex.vue";
import ExcelImportPage from "../pages/portal/TempExcelBulk.vue";
import axios from "@/utils/axios";
import store from "@/store";
const routes = [
  {
    path: "/",
    name: "index",
    component: LandingPage,
    meta: {
      layout: "General",
      requiresAuth: false,
      type: "client",
    },
  },
  {
    path: "/deped/alert/transaction/temp/meta/api/raw/releasing/:id",
    name: "onlinepayment",
    component: OnlinePayment,
    meta: {
      layout: "General",
      requiresAuth: false,
      type: "client",
    },
  },
  {
    path: "/deped/alert/transaction/temp/meta/api/raw/:id",
    name: "message",
    component: CompletedPage,
    meta: {
      layout: "General",
      requiresAuth: false,
      type: "client",
    },
  },
  {
    path: "/deped/alert/transaction/temp/meta/api/raw/hold/:id",
    name: "holdMessage",
    component: HoldPaymentPage,
    meta: {
      layout: "General",
      requiresAuth: false,
      type: "client",
    },
  },
  {
    path: "/deped/category",
    name: "categoryOption",
    component: CategoryOptionPage,
    meta: {
      layout: "General",
      requiresAuth: false,
      type: "client",
    },
  },
  {
    path: "/manual-agentcode",
    name: "manual",
    component: ManualAgentCodePage,
    meta: {
      layout: "General",
      requiresAuth: false,
      type: "client",
    },
  },
  {
    path: "/qr-scanner",
    name: "qr",
    component: QRScannerPage,
    meta: {
      layout: "General",
      requiresAuth: false,
      type: "client",
    },
  },
  {
    path: "/qr-scanner/application-form/deped/:id",
    name: "application",
    component: ApplicationPage,
    meta: {
      layout: "General",
      requiresAuth: false,
      type: "client",
    },
  },
  {
    path: "/deped/ims/account/application/retrieve/",
    name: "retrieve",
    component: RetrievePage,
    meta: {
      layout: "General",
      requiresAuth: false,
      type: "client",
    },
  },
  {
    path: "/admin/auth/login",
    name: "authlogin",
    component: AuthLoginPage,
    meta: {
      layout: "General",
      requiresAuth: false,
      type: "client",
    },
  },
  {
    path: "/admin/auth/portfolio",
    name: "authAgentPortfolio",
    component: AgentPortfolioPage,
    meta: {
      layout: "Account",
      requiresAuth: true,
      type: "admin",
      transition: "slide-left",
    },
  },
  {
    path: "/admin/auth/profile",
    name: "authAgentProfile",
    component: AgentProfilePage,
    meta: {
      layout: "Account",
      requiresAuth: true,
      type: "admin",
    },
  },
  {
    path: "/admin/auth/setting",
    name: "authAgentSetting",
    component: AgentSettingPage,
    meta: {
      title: "Account",
      layout: "Setting",
      requiresAuth: true,
      type: "admin",
    },
  },
  {
    path: "/admin/auth/setting/changepassword",
    name: "authAgentChangePassword",
    component: AgentChangePasswordPage,
    meta: {
      title: "Change Password",
      layout: "Setting",
      requiresAuth: true,
      type: "admin",
    },
  },
  {
    path: "/admin/auth/setting/profile",
    name: "authAgentSettingProfile",
    component: AgentSettingProfilePage,
    meta: {
      title: "My Profile",
      layout: "Setting",
      requiresAuth: true,
      type: "admin",
    },
  },
  {
    path: "/admin/auth/setting/about",
    name: "authAgentSettingAbout",
    component: AgentSettingAboutPage,
    meta: {
      title: "About the System",
      layout: "Setting",
      requiresAuth: true,
      type: "admin",
    },
  },
  {
    path: "/admin/auth/setting/statistic",
    name: "authAgentSettingStatistic",
    component: AgentSettingStatisticPage,
    meta: {
      title: "Statistic",
      layout: "Setting",
      requiresAuth: true,
      type: "admin",
    },
  },
  {
    path: "/admin/auth/portfolio/client/:id",
    name: "authAgentPortfolioClientProfile",
    component: AgentPortfolioClientProfilePage,
    meta: {
      layout: "Account",
      requiresAuth: true,
      type: "admin",
    },
  },
  {
    path: "/admin/auth/portfolio/clients/",
    name: "authAgentPortfolioClient",
    component: AgentPortfolioClientPage,
    meta: {
      layout: "Account",
      requiresAuth: true,
      type: "admin",
    },
  },
  {
    path: "/excel",
    name: "execlPage",
    component: ExcelImportPage,
    meta: {
      layout: "General",
      requiresAuth: false,
      type: "client",
    },
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach(async (to, from, next) => {
  document.title = `IMS DEPED`;
  let isAuthenticate;
  try {
    let { data } = await axios.get(`/admin/authorization`);
    isAuthenticate = data.authorized;
  } catch (error) {
    console.log(error);
  }
  if (to.path === "/admin/auth/logout") {
    await axios.post(`/admin/portal/auth/logout`);
    next({
      path: "/admin/auth/login",
    });
  }
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!isAuthenticate) {
      next({ path: "/admin/auth/login" });
    } else if (
      store.state.AuthAgentIsForcedPassword &&
      to.path !== "/admin/auth/setting/changepassword"
    ) {
      next({ path: "/admin/auth/setting/changepassword" });
    } else {
      next();
    }
  } else {
    if (to.name === "authlogin" && isAuthenticate) {
      next({ path: "/admin/auth/profile" });
    } else {
      next();
    }
  }
});
export default router;
