<template>
  <div class="wrapper">
    <div class="container">
      <div class="divider">
        <div class="header-icon">
          <greetingSVG id="svg" />
          <div class="header-message">
            <h2>You're almost done!</h2>
            <p>
              To complete your application, input the POS receipt or
              transaction/service invoice number corresponding to your receipt.
              Then click submit. You will shortly receive an email notification.
            </p>
          </div>
          <form @submit.prevent="submitHandler()">
            <div class="input-confirmation-code">
              <div class="tooltip-container">
                <label for="reference-number">Reference Number</label>
                <input
                  id="reference-number"
                  type="password"
                  v-model="confirmationCode"
                  @input="checkIfValid()"
                  required
                />
                <span class="tooltip-text" v-if="isValidationOne">{{
                  isValidationOneMessage
                }}</span>
              </div>
            </div>
            <div class="input-confirmation-code">
              <div class="tooltip-container">
                <label for="retype-reference-number"
                  >Re-type Reference Number</label
                >
                <input
                  id="retype-reference-number"
                  type="password"
                  v-model="ReconfirmationCode"
                  @input="checkMatchHandler()"
                  required
                />
                <span class="tooltip-text" v-if="isValidationTwo">{{
                  isValidationTwoMessage
                }}</span>
              </div>
            </div>
            <div class="btn-confirmation-code loading-container">
              <app-loading v-if="isLoading" />
              <input
                type="submit"
                :value="isLoading ? '' : 'Submit'"
                :disabled="isDisabled"
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import adminData from "@/data/ProductionData";
import greetingSVG from "@/components/svgs/greetingSVG.vue";
import swalAlert from "@/utils/reusables/SwalAlert";
import { useRoute } from "vue-router";
import { ref, inject, onMounted } from "vue";
export default {
  components: {
    greetingSVG,
  },
  setup() {
    const { applicationProcess, isLoading } = adminData();
    const { conifrmationReferenceHandler } = swalAlert();
    const route = useRoute();
    const store = inject("$store");
    const transactionID = route.params.id;
    const confirmationCode = ref(null);
    const ReconfirmationCode = ref(null);
    const isDisabled = ref(false);
    const ifMatched = ref(false);
    const isValidationOne = ref(false);
    const isValidationOneMessage = ref(null);
    const isValidationTwo = ref(false);
    const isValidationTwoMessage = ref(null);
    onMounted(() => {});

    const checkIfValid = () => {
      if (!confirmationCode.value.length) {
        isValidationOne.value = false;
      } else if (confirmationCode.value.length === 12) {
        isValidationOne.value = false;
      } else if (confirmationCode.value.length > 12) {
        isValidationOneMessage.value =
          "Your reference number must not exceed 12 characters.";
        isValidationOne.value = true;
      } else {
        isValidationOneMessage.value =
          "Your reference number must be exactly 12 characters.";
        isValidationOne.value = true;
      }
    };
    const checkMatchHandler = () => {
      if (!ReconfirmationCode.value.length) {
        isValidationTwo.value = false;
      } else if (confirmationCode.value === ReconfirmationCode.value) {
        ifMatched.value = true;
        isValidationTwo.value = false;
      } else {
        ifMatched.value = false;
        isValidationTwoMessage.value =
          "The retype reference number you entered do not match.";
        isValidationTwo.value = true;
      }
    };
    const submitHandler = () => {
      if (!isValidationOne.value && ifMatched.value) {
        isDisabled.value = true;
        isLoading.value = true;
        if (isDisabled.value) {
          applicationProcess(
            transactionID,
            confirmationCode.value,
            route.query.paymentMethod
          );
          //updateTransaction(transactionID, confirmationCode.value);
          store.commit("resetState");
        } else {
          isDisabled.value = false;
        }
      } else {
        conifrmationReferenceHandler();
      }
    };

    return {
      isDisabled,
      isLoading,
      ifMatched,
      checkMatchHandler,
      submitHandler,
      confirmationCode,
      ReconfirmationCode,
      transactionID,
      conifrmationReferenceHandler,
      checkIfValid,
      isValidationOne,
      isValidationOneMessage,
      isValidationTwo,
      isValidationTwoMessage,
    };
  },
};
</script>
<style scoped>
.divider {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.header-icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.header-message {
  text-align: center;
  padding: 0 20px;
}

.header-message h2 {
  color: var(--accent-color);
  margin: 15px 0;
  font-size: 24px;
}

.header-message p {
  line-height: 1.6;
  color: var(--semi-light-color);
  font-size: 16px;
  text-indent: 8%;
  margin: 0;
}

.input-confirmation-code {
  width: 100%;
  margin-bottom: 20px;
}

label {
  font-size: 14px;
  color: var(--light-color);
  margin: 42px 0px;
}

.input-confirmation-code .tooltip-container input {
  height: 50px;
  font-size: 18px;
  width: 100%;
  padding: 0 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.tooltip-container {
  position: relative;
  display: inline-block;
  width: 100%;
}

.tooltip-text {
  visibility: hidden;
  width: 200px;
  background-color: #333;
  color: #fff;
  text-align: center;
  border-radius: 4px;
  padding: 8px;
  position: absolute;
  top: -45px; /* Position above the input */
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  font-size: 14px;
  z-index: 1;
}

.tooltip-text::after {
  content: "";
  position: absolute;
  top: 100%; /* Arrow at the bottom */
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #333 transparent transparent transparent;
}

.tooltip-container:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}

.btn-confirmation-code input {
  height: 50px;
  width: 100%;
  font-size: 18px;
  text-align: center;
  background-color: var(--secondary-color);
  color: var(--light-color);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.btn-confirmation-code input:hover {
  background-color: var(--hover-color);
}

.btn-confirmation-code input:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

#svg {
  margin: 30px 0;
  height: 140px;
  width: 140px;
}
form {
  margin: 0px 28px;
}
</style>
