<template>
  <div class="wrapper">
    <div class="container">
      <div class="divider">
        <div class="header-title">
          <h2>Payment Confirmation</h2>
        </div>
        <div class="content">
          <div class="col-data">
            <span class="description">Plan Code</span>
            <span class="value">{{ $store.state.planDetails.PlanCode }}</span>
          </div>
          <div class="col-data">
            <span class="description">Premium</span>
            <span class="value">{{
              pesoFormat($store.state.formData.premium)
            }}</span>
          </div>
          <div class="col-data">
            <span class="description">Coverage</span>
            <span class="value">1 Year</span>
          </div>
          <div class="col-seperator">
            <hr />
          </div>
          <div class="col-data-total">
            <span class="description">
              <h3>Total Amount:</h3>
            </span>
            <span class="value"
              ><h3>
                {{ pesoFormat(totalPaidAmount) }}
              </h3></span
            >
          </div>
        </div>
        <div class="payment-method">
          <div class="selection">
            <span> <h4>Select Payment Method</h4></span>
            <ul>
              <li
                v-for="(item, index) in paymentItems"
                :key="index"
                :class="{ active: item.description === paymentMethod }"
                class="card-palette"
                @click="
                  selectedHandler((paymentMethod = item.description), item)
                "
              >
                <input
                  type="radio"
                  name="payment"
                  :value="item.description"
                  v-model="paymentMethod"
                />

                <label for="">{{ item.description }}</label>
                <span v-for="(item, index1) in item.icon" :key="index1">
                  <img
                    class="icons"
                    :src="require(`@/assets/icons/${item}`)"
                    alt="icon"
                  />
                </span>
              </li>
            </ul>
            <div class="subCategory" v-if="isSubcategory">
              <select class="card-palette" v-model="selectedBankname">
                <option disabled value="">--Please select--</option>
                <option v-for="(item, index) in bankNameItems" :key="index">
                  {{ item.name }}
                </option>
              </select>
            </div>
          </div>

          <div class="col-seperator">
            <div class="important">
              <span class="note">
                <h5>Please read</h5>
                <p>
                  This program gives the member a 15-days “free look” period
                  during which, he/she may review the program's provisions and
                  if no benefit has been availed of, the member may opt to have
                  his/her membership cancelled and have the amount paid via
                  Gcash or credit card refunded.
                </p>
              </span>
            </div>
            <div class="confirmation" v-if="paymentMethod !== 'Cash & Card'">
              <span
                >For GCash or credit card payment, input the amount to be paid.
                Click PROCEED TO PAYMENT.</span
              >
              <input
                :class="{
                  error: !isMatch && confirmAmount,
                }"
                type="number"
                v-model="confirmAmount"
                @input="confirmHandler()"
              />
            </div>
          </div>
          <div class="btn-checkout loading-container">
            <app-loading v-if="loading" />
            <input
              v-if="loading"
              :disabled="loading"
              type="button"
              :value="!loading ? 'Proceed to Payment' : null"
              @click.once="nextHandler()"
            />
            <input
              v-else
              type="button"
              :value="!loading ? 'Proceed to Payment' : null"
              @click="nextHandler()"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import validationAlert from "@/utils/reusables/SwalAlert";
import ApplicationData from "@/data/ApplicationData";
import { computed, inject, ref } from "vue";
export default {
  components: {},
  setup() {
    const { createdClients, OnlinePaymentProcess, loading, countdown } =
      ApplicationData();
    const { paymentConfirmation } = validationAlert();
    const store = inject("$store");
    const paymentMethod = ref(null);
    const paymentMethodid = ref(0);
    const selectedTransaction = ref(null);
    const confirmAmount = ref(0);
    const isMatch = ref(true);
    const cardAmount = ref(0);
    const cashAmount = ref(0);
    const isError = ref(false);
    const isSubcategory = ref(false);
    const selectedBankname = ref("");
    const paymentMethodItems = ref([
      {
        id: 1, //default pos
        description: "Credit/Debit Card (via POS)",
        isActive: true,
        icon: ["visa.png", "mastercard.png", "amex.png"],
        online: false,
      },
      {
        id: 2, //default online
        description: "Online Payment",
        isActive: true,
        icon: ["visa.png", "mastercard.png", "amex.png"],
        online: true,
      },
      {
        id: 3, //default check
        description: "Check Payment",
        isActive: true,
        icon: ["cheque.png"],
        online: false,
      },
    ]);

    const bankNameItems = ref([
      {
        id: 1,
        name: "BPI",
      },
      {
        id: 2,
        name: "BDO.",
      },
      {
        id: 3,
        name: "China Bank",
      },
      {
        id: 4,
        name: "DBP",
      },
      {
        id: 5,
        name: "LANDBANK",
      },
      {
        id: 6,
        name: "Metrobank",
      },
      {
        id: 7,
        name: "PNB",
      },
      {
        id: 8,
        name: "RCBC",
      },
      {
        id: 9,
        name: "Security Bank",
      },
      {
        id: 10,
        name: "UnionBank",
      },
    ]);
    const paymentItems = computed(() => {
      return paymentMethodItems.value.filter((val) => {
        return val.isActive;
      });
    });
    const pesoFormat = (val) => {
      let modified = val.toLocaleString("en-PH", {
        style: "currency",
        currency: "PHP",
      });
      return `${modified}`;
    };
    const confirmHandler = () => {
      if (confirmAmount.value === 0) {
        isMatch.value = true;
      } else if (confirmAmount.value === totalPaidAmount.value) {
        isMatch.value = true;
      } else {
        isMatch.value = false;
      }
    };
    const selectedHandler = (val, payLoad) => {
      isMatch.value = true;
      cardAmount.value = 0;
      cashAmount.value = 0;
      confirmAmount.value = 0;
      selectedTransaction.value = null;

      if (payLoad.online) {
        const uniqueRandomNumber =
          Date.now().toString().slice(-7) +
          Math.floor(Math.random() * 1000)
            .toString()
            .padStart(3, "0");
        OnlinePaymentProcess(uniqueRandomNumber);
        /*let invoiceno = 183943;
        let name = `${store.state.formData.firstname} ${store.state.formData.lastname}`;
        let email = store.state.formData.email;
        let amount = store.state.formData.premium;
        let url = `https://test.dragonpay.ph/GenPay.aspx?merchantid=SAMPLEGEN&invoiceno=${invoiceno}&name=${name}&email=${email}&amount=${amount}`;

        window.open(url, "_blank");*/
      }

      isSubcategory.value =
        payLoad.description === "Check Payment" ? true : false;
      paymentMethodid.value = payLoad.id;
    };
    const nextHandler = () => {
      if (isMatch.value && paymentMethod.value) {
        if (isSubcategory.value) {
          if (selectedBankname.value != "") {
            loading.value = true;
            createdClients(
              paymentMethod.value,
              confirmAmount.value,
              paymentMethodid.value
            );
          } else {
            paymentConfirmation("select");
          }
        } else {
          loading.value = true;
          createdClients(
            paymentMethod.value,
            confirmAmount.value,
            paymentMethodid.value
          );
        }
      } else {
        paymentConfirmation();
      }
    };
    const totalPaidAmount = computed(() => {
      return store.state.formData.premium;
    });
    return {
      confirmAmount,
      paymentMethod,
      paymentMethodid,
      paymentMethodItems,
      isMatch,
      cashAmount,
      cardAmount,
      loading,
      countdown,
      pesoFormat,
      confirmHandler,
      selectedHandler,
      nextHandler,
      createdClients,
      paymentConfirmation,
      selectedTransaction,
      isError,
      totalPaidAmount,
      paymentItems,
      isSubcategory,
      bankNameItems,
      selectedBankname,
    };
  },
};
</script>
<style scoped>
.wrapper {
  color: var(--light-color);
}
.divider {
  display: flex;
  flex-direction: column;
}
.content {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.content .col-data {
  margin: 0px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}
.col-seperator {
  margin: 5px 20px;
}
.content .col-data-total {
  margin: 15px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}
.content .col-data .description,
.content .col-data-total .description {
  font-weight: 500;
}
.content .col-data .value,
.content .col-data-total .value {
  font-weight: 600;
}
.col-seperator .confirmation {
  display: flex;
  gap: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.col-seperator .confirmation input {
  min-width: 100%;
  text-align: right;
  min-height: 60px;
}
.col-seperator .important {
  padding: 0px 0px 18px 0px;
}
.col-seperator .important .note h5 {
  color: var(--error-color);
  font-size: 14px;
  padding-bottom: 5px;
}
.col-seperator .important .note p {
  font-size: 14px;
  line-height: 18px;
}
.payment-method {
  margin: 20px;
}
.payment-method .selection ul,
.subCategory {
  list-style-type: none;
  margin: 18px 15px;
}
.subCategory select {
  width: 100%;
}
.subCategory select > option {
  background-color: transparent;
  box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
  background: rgba(226, 56, 56, 0);
  color: var(--dark-color);
}
.payment-method .selection ul > li {
  height: 60px;
  padding: 0px 14px;
  margin: 5px 0px;
  display: flex;
  gap: 8px;
  align-items: center;
  border: 1px solid var(--dark-color);
  border-radius: 18px;
  color: var(--light-color);
}
.payment-method .selection ul > li:hover {
  background-color: var(--hover-color);
  border: 1px solid var(--secondary-color);
  color: var(--light-color);
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.active {
  background-color: var(--secondary-color) !important;
  border: 1px solid var(--secondary-color);
  box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px,
    rgba(0, 0, 0, 0.1) 0px 2px 4px 0px,
    rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;
  color: var(--light-color) !important;
}
.payment-method .btn-checkout {
  margin: 20px 0px;
}
.payment-method .btn-checkout input {
  min-width: 100%;
  height: 60px;
}
.icons {
  height: 36px;
  width: 36px;
}
</style>
