<template>
  <div class="wrapper">
    <ul>
      <li
        class="card-palette"
        v-for="(item, index) in categoryItems"
        :key="index"
      >
        <router-link class="items" :to="item.route">
          <component :is="item.iconComponent" class="icon"></component>
          <h4>{{ item.title }}</h4>
          <h5>{{ item.description }}</h5>
        </router-link>
      </li>
    </ul>
  </div>
</template>
<script>
import qrScannerIcon from "@/components/svgs/scanQRSVG.vue";
import InputCodeIcon from "@/components/svgs/inputSVG.vue";
import { reactive } from "vue";
export default {
  components: {
    qrScannerIcon,
    InputCodeIcon,
  },
  setup() {
    const categoryItems = reactive([
      {
        id: 1,
        title: "SCAN QR CODE",
        iconComponent: "qrScannerIcon",
        description: "Please scan markerting executive qr code",
        route: "/qr-scanner",
      },
      {
        id: 1,
        title: "INPUT CODE",
        iconComponent: "InputCodeIcon",
        description:
          "Please enter a code provided by Marketing Executive Officer",
        route: "/manual-agentcode",
      },
    ]);
    return {
      categoryItems,
    };
  },
};
</script>
<style scoped>
.wrapper {
  margin-top: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
  text-align: center;
}
ul > li:hover {
  background-color: var(--secondary-color);
}
.items {
  margin: 10px 0;
  min-height: 180px;
  min-width: 320px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 8px;
}
h4 {
  font-weight: 600;
  color: var(--semi-light-color);
}
h5 {
  margin-top: -5px;
  font-weight: 400;
  color: var(--semi-light-color);
  max-width: 300px;
  font-size: 12px;
}
.icon {
  height: 68px;
  width: 68px;
}
a {
  text-decoration: none;
}
</style>
