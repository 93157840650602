import { createApp } from "vue";
import App from "./App.vue";
import axios from "./utils/axios";
import router from "./router";
import store from "./store";
import Swal from "sweetalert2";
import moment from "moment";
import "./assets/css/styles.css";
import "./assets/css/table.css";
import "./assets/css/qr-code.css";

import AccountMenuBar from "./components/common/HeroBottomMenuBar.vue";
import HeroLoading from "./components/common/HeroLoading.vue";
import HeroLoadingTwo from "./components/common/HeroLoadingTwo.vue";
import HeroLoadingThree from "./components/common/HeroLoadingThree.vue";
const app = createApp(App);

app.use(store);
app.use(router);
app.provide("$axios", axios);
app.provide("$router", router);
app.provide("$store", store);
app.provide("$swal", Swal);
app.provide("$moment", moment);
app.component("app-menu-bar", AccountMenuBar);
app.component("app-loading", HeroLoading);
app.component("app-loading-spin", HeroLoadingTwo);
app.component("app-loading-sending", HeroLoadingThree);
app.mount("#app");
