<template>
  <div class="setting-container">
    <form class="form-group" @submit.prevent="submitHandler()">
      <div class="password-row">
        <input
          class="card-palette"
          :type="isShowCurrentPassword ? 'text' : 'password'"
          v-model="oldPassword"
          placeholder="current password"
          autocomplete="current-password"
          required
        />
        <span
          class="password-icon"
          @click="isShowCurrentPassword = !isShowCurrentPassword"
        >
          <showPasswordSVG class="icon" v-if="isShowCurrentPassword" />
          <hidePasswordSVG class="icon" v-else />
        </span>
      </div>

      <div class="password-row">
        <input
          class="card-palette"
          :type="isShowNewPassword ? 'text' : 'password'"
          v-model="newPassword"
          placeholder="new password"
          autocomplete="new-password"
          required
        />
        <span
          class="password-icon"
          @click="isShowNewPassword = !isShowNewPassword"
        >
          <showPasswordSVG class="icon" v-if="isShowNewPassword" />
          <hidePasswordSVG class="icon" v-else />
        </span>
      </div>

      <div class="password-row">
        <input
          class="card-palette"
          :type="isShowConfirmPassword ? 'text' : 'password'"
          v-model="rePassword"
          placeholder="retype-password"
          autocomplete="new-password"
          required
        />
        <span
          class="password-icon"
          @click="isShowConfirmPassword = !isShowConfirmPassword"
        >
          <showPasswordSVG class="icon" v-if="isShowConfirmPassword" />
          <hidePasswordSVG class="icon" v-else />
        </span>
      </div>

      <div class="loading-container">
        <app-loading v-if="isLoading" />
        <input
          class="submit"
          type="submit"
          :value="isLoading ? '' : 'Update Password'"
        />
      </div>
    </form>

    <div
      v-if="isMessage"
      class="messageHandler"
      :class="isSuccess ? 'success' : 'failed'"
    >
      {{ isMessageText }}
    </div>
  </div>
</template>

<script>
import hidePasswordSVG from "@/components/svgs/hidePasswordSVG.vue";
import showPasswordSVG from "@/components/svgs/showPasswordSVG.vue";
import ChangePassword from "@/data/AccountPortalData";
import { reactive, toRefs, ref } from "vue";
export default {
  components: {
    hidePasswordSVG,
    showPasswordSVG,
  },
  setup() {
    const isShowCurrentPassword = ref(false);
    const isShowNewPassword = ref(false);
    const isShowConfirmPassword = ref(false);
    const {
      authChangePasswordHandler,
      isLoading,
      isMessage,
      isMessageText,
      isSuccess,
    } = ChangePassword();
    const formData = reactive({
      oldPassword: null,
      newPassword: null,
      rePassword: null,
    });
    const submitHandler = () => {
      isLoading.value = true;
      authChangePasswordHandler(formData);
    };
    return {
      authChangePasswordHandler,
      submitHandler,
      ...toRefs(formData),
      isLoading,
      isSuccess,
      isMessage,
      isMessageText,
      isShowCurrentPassword,
      isShowNewPassword,
      isShowConfirmPassword,
    };
  },
};
</script>
<style scoped>
.form-group {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.password-row {
  display: flex;
  align-items: center;
  position: relative;
}
.password-row input {
  width: 100%;
  padding-right: 40px; /* Space for the icon */
}
.password-icon {
  position: absolute;
  right: 10px;
  cursor: pointer;
}
.loading-container {
  margin: 20px 0;
}
.submit {
  min-height: 50px;
  width: 100%;
}
.icon {
  height: 25px;
  width: 25px;
}
.messageHandler {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 8px 0px;
  border-radius: 10px;
  color: var(--light-color);
  font-weight: 500;
}
.success {
  background-color: var(--valid-color);
}
.failed {
  background-color: var(--error-color);
}
input[type="text"] {
  text-transform: none;
}
input::placeholder {
  color: var(--semi-light-color);
}
</style>
