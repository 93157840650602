<template>
  <div class="logo">
    <img src="../../../assets/brand-logo.png" />
  </div>
  <div class="container">
    <h4 class="label-note">Enter Marketing Executive Code</h4>
    <div class="input-wrapper">
      <input
        v-for="i in AGENTCODE"
        :key="`input-el-${i}`"
        :ref="addInputEls"
        :id="`input-el-${i}`"
        type="text"
        pattern="[0-9]*"
        @input="handleInputElsInput($event, i - 1)"
      />
    </div>
    <div class="action-button">
      <input class="back" type="button" value="Back" @click="cancelHandler" />
    </div>
  </div>
</template>
<script>
import { ref, computed, onMounted, inject } from "vue";
export default {
  setup() {
    // State
    const router = inject("$router");
    const codeValue = ref(Array.from({ length: 5 }, () => ""));
    const isInProgress = ref(false);
    const inputEls = ref([]);
    const AGENTCODE = ref(5);
    onMounted(() => {
      const firstEl = inputEls.value[0];
      if (firstEl) {
        firstEl.focus();
      }
    });

    const codeValueAfterJoin = computed(() => {
      return codeValue.value.join("").replace(/[^0-9]/g, "");
    });

    const iscodeValueSomeEmpty = computed(() => {
      return codeValue.value.some((v) => v !== 0 && !v);
    });

    // Methods
    const handleInputElsInput = (e) => {
      const val = e.target.value.replace(/[^0-9]/g, "");
      if (!val || isNaN(val)) {
        e.target.value = "";
        updatecodeValue();
        return;
      }

      let idx = 0;
      let el = e.target;

      if (val.length === 0) {
        updatecodeValue();
        return;
      }

      if (isInProgress.value) return;
      isInProgress.value = true;

      if (val.length === 1) {
        el.value = val;
        el = el?.nextElementSibling ?? null;
      }

      if (val.length === 2) {
        el.value = val.substring(1, 2);
        el = el?.nextElementSibling ?? null;
      }

      while (val.length > 2 && idx < val.length && !!el) {
        el.value = val.substring(idx, idx + 1);
        el = el?.nextElementSibling ?? null;
        idx++;
      }

      el?.focus();
      isInProgress.value = false;
      updatecodeValue();

      if (codeValueAfterJoin.value.length === 5) {
        router.push(
          `/qr-scanner/application-form/deped/${codeValueAfterJoin.value}`
        );
      }
    };

    const updatecodeValue = () => {
      codeValue.value.forEach((v, i) => {
        codeValue.value[i] = inputEls.value[i]?.value || "";
      });
    };

    const addInputEls = (el) => {
      if (el && !inputEls.value.includes(el)) {
        inputEls.value.push(el);
      }
    };
    const cancelHandler = () => {
      router.push({ name: "categoryOption" });
    };

    return {
      codeValue,
      isInProgress,
      inputEls,
      codeValueAfterJoin,
      iscodeValueSomeEmpty,
      handleInputElsInput,
      addInputEls,
      AGENTCODE,
      cancelHandler,
    };
  },
};
</script>
<style scoped>
@media only screen and (max-width: 430px) {
  .logo {
    margin: 35px 0px 20px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .logo img {
    height: 40%;
    width: 40%;
  }

  .container {
    width: 80%;
    margin: auto;
    padding: 28px 0;
  }
  .container .input-wrapper {
    margin-top: 25px;
    width: 100%;
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
  }

  .container .input-wrapper input {
    width: 2em;
    font-size: 24px;
    line-height: 1.5;
    text-align: center;
  }

  .container h5 {
    text-align: center;
    margin-top: 24px;
    color: var(--semi-light-color);
    font-weight: 500;
  }
  .action-button {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .action-button input {
    min-height: 50px;
  }
  .back {
    background-color: var(--secondary-color);
  }
}
</style>
