<template>
  <div class="wrapper">
    <HeroTopHeader />
    <div class="container">
      <div class="content">
        <div class="layout">
          <div class="web">
            <h1>GET THE PROTECTION THEY DESERVE!</h1>
            <p>{{ headerMessage }}</p>
          </div>
          <div class="mobile">
            <p>
              {{ headerMessage }}
            </p>
            <router-link to="/deped/category" class="get-started">
              Get started
              <rightSVG
            /></router-link>
            <div class="portal">
              Have a pending application for IMS ANEC Membership Program?
              <span>click <b @click="routeHandler"> here </b></span>
            </div>
            <HeroFooter />
          </div>
        </div>
        <div class="avatar">
          <img src="../../../assets/images/banner.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import rightSVG from "@/components/svgs/rightSVG.vue";
import HeroTopHeader from "@/components/common/HeroTopHeader.vue";
import HeroFooter from "@/components/common/HeroFooter.vue";
import { computed, ref, inject } from "vue";
export default {
  components: {
    rightSVG,
    HeroTopHeader,
    HeroFooter,
  },
  setup() {
    const router = inject("$router");
    const isModal = ref(false);
    const headerMessage = computed(() => {
      return `Unlock the door to worrying less and living more! Let us provide you with the key card that 
              will help you take care of your physical and mental well-being. Enjoy access to state-of-the-art 
              holistic services without straining your finances.`;
    });

    const routeHandler = () => {
      router.push({ name: "retrieve" });
    };

    return {
      isModal,
      headerMessage,
      routeHandler,
    };
  },
};
</script>
<style scoped>
.container {
  max-width: 1050px;
  margin: auto;
  padding: 3% 5% 0% 5%;
}
.container .content {
  display: flex;
  flex-direction: column;
  gap: 25px;
  align-items: center;
}
.container .content .layout .web {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.container .content .layout .web h1 {
  font-size: 56px;
  color: var(--dark-color);
  text-align: center;
}
.container .content .layout .web p {
  margin: 25px 0px;
  font-size: 18px;
  color: var(--dark-color);
  text-align: center;
  line-height: 28px;
}
.container .content .layout .mobile {
  display: none;
}
.container .avatar {
  margin: 10px 0px;
}
.container .avatar img {
  height: 680px;
  width: auto;
}
@media only screen and (max-width: 430px) and (min-width: 320px) {
  .wrapper {
    color: var(--semi-light-color);
  }
  .container {
    display: flex;
    gap: 10px;
    flex-direction: column;
    padding: 15% 5% 0% 5%;
  }
  .container .content .layout .web {
    display: none;
  }
  .container .content .layout .mobile {
    display: block;
  }
  .container .content .layout .mobile p {
    line-height: 26px;
    font-size: 18px;
    font-family: var(--primary-font);
    font-weight: 400;
    display: block;
  }
  .container .content .avatar {
    display: none;
  }
  .container .content .layout .mobile .get-started {
    margin: 35px 0px 8px 0px;
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: center;
    padding: 20px;
    background: transparent;
    border-radius: 30px;
    background-color: var(--secondary-color);
    font-family: Montserrat;
    color: var(--light-color);
    font-size: 21px;
    max-width: 180px;
    font-weight: 600;
    text-decoration: none;
  }
  .container .content .layout .mobile .get-started:hover {
    background-color: var(--hover-color);
  }
  .container .content .layout .mobile .portal {
    margin: 15px 0px;
  }
  .container .content .layout .mobile .portal b {
    color: var(--secondary-color);
  }
  .container .content .layout .mobile .portal b:hover {
    color: var(--hover-color);
  }
}
</style>
