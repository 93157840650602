<template>
  <div class="wrapper">
    <div class="portfolio-header">
      <h2>
        Hello, <span>{{ shortName }}!</span>
      </h2>
    </div>

    <div class="group-container">
      <div class="portfolio card-palette">
        <h1>{{ countTotal }}</h1>
        <h3>Total Clients</h3>
      </div>
    </div>
    <div class="group-container">
      <div class="col-grid">
        <div class="col-paid card-palette">
          <span class="value">{{ countPaid }}</span>
          <span>Paid</span>
        </div>
        <div class="col-unpaid card-palette">
          <span class="value">{{ countUnpaid }}</span>
          <span>Pending</span>
        </div>
      </div>
    </div>
    <div class="group-container">
      <span class="group-title">
        <h3>Recent Transaction</h3>
        <router-link :to="`/admin/auth/portfolio/clients/`">
          <view-all class="icon" />
        </router-link>
      </span>
      <div v-if="isRender">
        <div v-if="itemClients.length">
          <div v-for="(item, index) in itemClients.slice(0, 5)" :key="index">
            <router-link :to="`/admin/auth/portfolio/client/${item.id}`">
              <div class="card card-palette">
                <h5 class="subtitle">Ref #{{ item.referenceNumber }}</h5>
                <div class="action-header">
                  <h4>{{ item.membername.toUpperCase() }}</h4>
                  <span
                    class="badge card-palette"
                    :class="
                      item.status === 'paid'
                        ? 'paid'
                        : item.status === 'complete'
                        ? 'complete'
                        : 'pending'
                    "
                  >
                    <h5>{{ item.status }}</h5>
                  </span>
                </div>

                <span class="grid">
                  <span class="group-details">
                    <PrincipalSVG
                      v-if="item.relationship === 'SAME' ? true : false"
                    />
                    <DependentSVG v-else />

                    <h5 class="value">
                      {{
                        item.relationship === "SAME" ? "Principal" : "Dependent"
                      }}
                    </h5>
                  </span>
                  <span class="flex-right">
                    <CashSVG />

                    <h5 class="value">
                      {{ pesoFormat(item.premium) }}
                    </h5>
                  </span>
                </span>
                <span class="group-details">
                  <LocationSVG />
                  <h5 class="value">{{ item.homeaddressOne.toUpperCase() }}</h5>
                </span>
              </div>
            </router-link>
          </div>
        </div>
        <div v-else>
          <div class="no-transaction">
            <ArchiveData />
            <h4>No Transaction Yet</h4>
            <h6>Get Started with Marketing Your Portfolio</h6>
          </div>
        </div>
      </div>
      <app-loading-spin class="spinner" v-else />
    </div>
  </div>
</template>
<script>
import DependentSVG from "@/components/svgs/dependentSVG.vue";
import PrincipalSVG from "@/components/svgs/principalSVG.vue";
import LocationSVG from "@/components/svgs/locationSVG.vue";
import CashSVG from "@/components/svgs/cashSVG.vue";
import ViewAll from "@/components/svgs/viewAllSVG.vue";
import ArchiveData from "@/components/svgs/archiveSVG.vue";
import AccountPortalData from "@/data/AccountPortalData";
import { computed, inject, onMounted } from "vue";
export default {
  components: {
    ViewAll,
    ArchiveData,
    CashSVG,
    DependentSVG,
    PrincipalSVG,
    LocationSVG,
  },
  setup() {
    const {
      countTotal,
      countPaid,
      countUnpaid,
      itemClients,
      authPortfolioClients,
      isRender,
    } = AccountPortalData();
    const store = inject("$store");
    const shortName = computed(() => {
      let fullname = store.state.AuthAgentProfile.agentname;
      return fullname.split(",")[1].trim().split(" ")[0];
    });
    onMounted(() => {
      authPortfolioClients();
    });
    const pesoFormat = (val) => {
      let modified = val.toLocaleString("en-PH", {
        style: "currency",
        currency: "PHP",
      });
      return `${modified}`;
    };
    return {
      shortName,
      countTotal,
      countPaid,
      countUnpaid,
      authPortfolioClients,
      itemClients,
      pesoFormat,
      isRender,
    };
  },
};
</script>
<style scoped>
.wrapper {
  margin: 40px 20px;
}
.portfolio-header {
  margin-bottom: 25px;
}
.portfolio-header h2 {
  color: var(--semi-light-color);
}
.portfolio-header h2 span {
  color: var(--accent-color);
}
.group-container {
  margin: 10px 0px 0px 0px;
}
.group-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.group-title h3 {
  padding: 10px 0px;
  color: var(--semi-light-color);
}
.col-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  height: 75px;
}
.col-paid,
.col-unpaid {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
  color: var(--semi-light-color);
}
.col-paid .value,
.col-unpaid .value {
  color: var(--accent-color);
}
.portfolio {
  min-height: 180px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.portfolio h1 {
  font-size: 54px;
  color: var(--accent-color);
  font-weight: 700;
}
.portfolio h3 {
  margin-top: 30px;
  font-size: 16px;
  color: var(--semi-light-color);
  font-weight: 400;
}
.card {
  margin: 12px 0px;
  min-height: 110px;
  padding: 10px;
}
.card h4 {
  padding: 5px 0px;
  color: var(--light-color);
  font-weight: 800;
}
.card .group-details {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
  padding: 0px 10px;
  margin: 6px 0px;
}
.card .group-details .value {
  color: var(--semi-light-color);
  font-weight: 600;
}
.subtitle {
  font-weight: 500;
  color: var(--light-color);
}
.action-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.flex-right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 5px;
  padding: 0px 10px;
  margin: 6px 0px;
  color: var(--semi-light-color);
  font-weight: 600;
}
.badge {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  border-radius: 9999px;
  font-size: 0.75rem;
  line-height: 1rem;
  color: #ffffff;
  text-transform: uppercase;
}
.paid {
  background-color: rgba(5, 255, 5, 0.473);
}
.pending {
  background-color: rgba(27, 27, 27, 0.473);
}
.complete {
  background-color: rgba(199, 196, 9, 0.911);
}
a {
  text-decoration: none;
}
.no-transaction {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  margin-top: 30px;
  flex-direction: column;
  gap: 5px;
  text-align: center;
}
.no-transaction h4 {
  color: var(--light-color);
  font-weight: 600;
}
.no-transaction h6 {
  color: var(--light-color);
  font-weight: 400;
}
</style>
