<template>
  <div v-if="isRender" class="container card-palette">
    <img :src="$store.state.AuthAgentProfileImage" alt="Agent Profile" />
    <span class="badge-code card-palette">{{ agentcode }}</span>
    <h3>{{ $store.state.AuthAgentProfile.agentname }}</h3>
    <div class="qr-container">
      <qrCodeGenerator
        v-if="QRCODE"
        class="qr-code"
        :text="QRCODE"
        :size="140"
        error-level="Q"
      ></qrCodeGenerator>
    </div>
  </div>
  <app-loading-spin class="spinner-container spinner" v-else />
</template>

<script>
import qrCodeGenerator from "vue-qrcode-component";
import QRGenerator from "@/utils/reusables/QRGenerator";
import { onMounted } from "vue";
export default {
  components: {
    qrCodeGenerator,
  },
  setup() {
    const { QRCODE, qrGenerator, isRender, agentcode } = QRGenerator();
    onMounted(async () => {
      qrGenerator();
    });
    return {
      QRCODE,
      isRender,
      agentcode,
    };
  },
};
</script>
<style scoped>
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 14px;
  min-height: 460px;
  max-width: 340px;
  margin: auto;
  margin-top: 60px;
}
img {
  height: 90px;
  width: 90px;
  clip-path: circle();
}
h3 {
  text-align: center;
  padding: 8px 0px;
  color: var(--accent-color);
}
.qr-container {
  background-color: var(--light-color);
  height: 180px;
  width: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
}
.spinner-container {
  max-width: 340px;
  margin: auto;
  margin-top: 180px;
}
.badge-code {
  border-radius: 12px;
  height: 30px;
  width: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
