import axios from "axios";

axios.defaults.baseURL = process.env.VUE_APP_API_URL;

axios.interceptors.request.use(
  function (config) {
    // Set the x-api-key header
    config.headers["x-api-key"] = process.env.VUE_APP_SECRET_KEY;
    // Set the Authorization header
    config.headers["x-token"] = localStorage.getItem(
      `${process.env.VUE_APP_AUTH_TOKEN}_XXX`
    );
    config.headers.Authorization =
      process.env.VUE_APP_PAYMENT_INTEGRATION_GATEWAY_API_PROD;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axios;
