import { inject } from "vue";
export default function validationAlert() {
  const Swal = inject("$swal");

  const errorHandler = (val) => {
    let errorMessage;
    if (val.message === "Network Error") {
      errorMessage = val.message;
    } else {
      errorMessage = val.response.data.errors.detail;
    }

    Swal.fire({
      position: "bottom",
      title: errorMessage,
      imageUrl: require("@/assets/images/internalError.png"),
      imageWidth: 210,
      imageHeight: 210,
      background: "var(--primary-color)",
      color: "var(--light-color)",
      imageAlt: "Custom image",
      showConfirmButton: true,
      confirmButtonColor: "var(--secondary-color)",
    });
  };
  const clientPortal = (value) => {
    let message;
    if (value === "fillout") {
      message =
        "<html><p>Prospect client to fill in the required fields.  <span style='color:var(--secondary-color)'>*</span></p></html>";
    } else if (value === "invalidemail") {
      message = "Invalid Email Address";
    } else if (value === "notQualified") {
      message = "Underage and overage applicants will not be allowed to enroll";
    }

    Swal.fire({
      position: "bottom",
      icon: "warning",
      background: "var(--primary-color)",
      color: "var(--light-color)",
      title: message,
      showConfirmButton: true,
      confirmButtonColor: "var(--secondary-color)",
    });
  };
  const termCondtionPortal = (value) => {
    let message;
    if (value === "link") {
      message =
        "Click PEC and exclusions. Read and scroll down, then click BACK button";
    } else if (value === "checkbox") {
      message = "Click the check box";
    }

    Swal.fire({
      position: "bottom",
      icon: "warning",
      title: message,
      background: "var(--primary-color)",
      color: "var(--light-color)",
      showConfirmButton: true,
      confirmButtonColor: "var(--secondary-color)",
    });
  };
  const pecTermCondtion = () => {
    Swal.fire({
      position: "center",
      icon: "info",
      title: "PEC & EXCLUSION",
      background: "var(--primary-color)",
      color: "var(--light-color)",
      html: `<div style="display:flex; justify-content: flex-start; flex-direction: column">
      <div style="text-align:left">
      <b>Pre-existing Conditions (PEC)</b>
      <br/>
      <br/>
      <span style="padding: 20px; font-size: 14px; ">
      A disability which is diagnosed before enrollment or during the first year of membership; 
      which presented signs and symptoms of which the member was aware of; and illnesses whose 
      pathogenesis had started prior to enrollment or during the first year of cover even if 
      the member was not aware thereof.
      </span>
      <br/>
      <br/>
      <span style="font-size: 14px; ">
      The following are automatically considered as Pre-Existing Conditions:
      <br/>
    <ul ">
    <li style="padding: 6px 10px">• Hypertension and other cardiovascular diseases</li>
    <li style="padding: 6px 10px">• Goiter and other thyroid disorders</li>
    <li style="padding: 6px 10px">• Hypertension and other cardiovascular diseases</li>
    <li style="padding: 6px 10px">• Cataracts/Glaucoma</li>
    <li style="padding: 6px 10px">• Diseased tonsils and sinus conditions requiring surgery</li>
    <li style="padding: 6px 10px">• Asthma/Chronic Obstructive Lung disease</li>
    <li style="padding: 6px 10px">• Cholecystitis</li>
    <li style="padding: 6px 10px">• Hernia/Benign Prostatic Hypertrophy</li>
    <li style="padding: 6px 10px">• Tumors/Cyst of internal organs</li>
    <li style="padding: 6px 10px">• Gastric and Duodenal Ulcers</li>
    <li style="padding: 6px 10px">• Pathological abnormalities of nasal septum or turbinates</li>
    <li style="padding: 6px 10px">• Endometriosis</li>
    <li style="padding: 6px 10px">• Tuberculosis</li>
    <li style="padding: 6px 10px">• Hallux valgus</li>
    <li style="padding: 6px 10px">• Calculi</li>
    <li style="padding: 6px 10px">• Tumors/Cyst on skin, muscular tissue, bone or any form of blood dyscracias
    </li>
    <li style="padding: 6px 10px">• Diabetes Mellitus</li>
    <li style="padding: 6px 10px">• Cerebrovascula Accident/Transient Ischemic Attack 
    </li>
    </ul>
      </span>
      </div>
<br/>
<br/>
      <div style="text-align:left">
      <b>Exclusions</b>
      <br/>
      <br/>
      <span style="font-size: 14px; ">
      The following are automatically considered as Exlusion Conditions:
      <br/>
    <ul ">
    <li style="padding: 6px 10px">• Service from Non-Accredited Physicians and/or Non-Accredited Hospitals and other health medical providers, including adverse medical conditions arising from treatment of the same except those stipulated under the provisions on emergency care services;</li>
    <li style="padding: 6px 10px">• Long term rehabilitation, custodial, domiciliary, covalescent, intermediate and psychiatric care;</li>
    <li style="padding: 6px 10px">• Cosmetic treatment/ reconstructive surgery to treat congenital deformities/abnormalities, e.g. herniorraphy, except reconstructive surgery necessary to treat a functional defect resulting from an accidental injury;</li>
    
    <li style="padding: 6px 10px">• Maternity care and other conditions as a result of pregnancy (unless otherwise specified in the plan);</li>
    <li style="padding: 6px 10px">• Physical examinations required for obtaining/continuing school, employment, insurance and government licensing;</li>
    <li style="padding: 6px 10px">• Experimental medical procedures, acupuncture, organ transplant, speech therapy, psychiatric care and rehabilitation;</li>
    
    <li style="padding: 6px 10px">• Sophisticated procedures such as but not limited to, thallium scintigraphy, angiography, sleep test. stereotactic radio surgery, brachytherapy, intraoperative radiation therapy, Auditory Brain Stem Response (ABR), Electronystagnography (ENG), Bone Mineral Density Measurement (BMD) and similar equipments; hyperalimentation; radiotherapy and chemotherapy for malignancies, allergy testing and treatment materials (unless otherwise specified);</li>
    <li style="padding: 6px 10px">• Services to diagnose and reverse infertility or fertility;</li>
    <li style="padding: 6px 10px">• Corrective devices, artificial aids, prosthetic devices such as nailing, pinning or bracing, oxygen dispensing equipment and oxygen outside of covered in-patient care;</li>

    <li style="padding: 6px 10px">• Treatment and diagnostic work-ups of congenital deformities, physical disabilities (such as but not limited to scoliosis, slipped disk, spondylosis and spianl stenosis) and abnormalities affecting functions of individuals, including complications;</li>
    <li style="padding: 6px 10px">• Treatment of injuries or illnesses resulting from self-destruction or attempted suicide (whether sane or insane), attributed to gross negligence, misconduct, reckless imprudence, intemperate use of drugs/alcoholic drinks, vicious and immoral habits and unnecessary exposure to imminent danger or hazard to health;</li>
    <li style="padding: 6px 10px">• Treatment of injuries or illness resulting from war, or any combat related activities while in military services;</li>

    <li style="padding: 6px 10px">• Sexually transmitted diseases like, AIDS, gonorrhea and syphilis;</li>
    <li style="padding: 6px 10px">• Oral surgery for purposes of beautification, including dental care following accidental injury to teeth;</li>
    <li style="padding: 6px 10px">• Treatment of injuries or illnesses caused directly or indirectly by participation in any hazardous sport or activity which includes, but is not limited to, sky diving; motor sports (e.g., motorbike racing, jet skiing); martial arts (e.g., taekwondo, wrestling); boxing; wrestling; bungee jumping; scuba diving; snorkeling; horseback riding; polo; mountain/rock climbing; and gymnastics;</li>
    
    <li style="padding: 6px 10px">• Corrective eye surgery for error of refraction such as myopia, astigmatism and hyperopia;</li>
    <li style="padding: 6px 10px">• Professional fees of medico-legal officers;</li>
    <li style="padding: 6px 10px">• Hospitalization and treatment outside the Philippines</li>
    
    <li style="padding: 6px 10px">• Epilepsy, hearing impairment, psoriasis and vitiligo</li>
    <li style="padding: 6px 10px">• Functional disorders of the mind such as anxiety reaction (psychosomatic, stress induced anxiety, hyperventilation syndrome, neurasthenia) and mood disorders;</li>
    <li style="padding: 6px 10px">• Auto-immune diseases such as but not limited to Guillain-Barre syndrome, Multiple sclerosis, Demyelinating disease, Parkinson's disease, Alzheimer's disease, Myasthenia Gravis, Epilepsy, Seizure Disorder, Endometriosis, Grave’s disease, Lupus, Peripheral neuropathy, Psoriasis, Rheumatoid Arthritis (RA)</li>
    <li style="padding: 6px 10px">• Platelets, packed RBC, and plasma transfusions (case to case basis).</li>
    </ul>
      </span>
      </div>
</div>`,
      showConfirmButton: true,
      confirmButtonText: "Back",
      allowOutsideClick: false,
      confirmButtonColor: "var(--secondary-color)",
    });
  };

  const refundPortal = () => {
    Swal.fire({
      position: "center",
      icon: "info",
      title: "Please call for assistance",
      html: `<div>
      <p>+63 927 446 8935</p>
      <p>+63 916 310 0912</p>
      </div>`,
      showConfirmButton: true,
    });
  };
  const dataPrivacy = () => {
    Swal.fire({
      position: "bottom",
      icon: "info",
      background: "var(--primary-color)",
      color: "var(--light-color)",
      title: "We value your privacy ",
      html: `<div>
      <p>We value your privacy. Data collection is handled with privacy in mind. We ensure transparency and security in how we gather and use your information. Rest assured, your privacy is our top priority. </p>
      </div>`,
      showConfirmButton: true,
      confirmButtonColor: "var(--secondary-color)",
    });
  };
  const paymentConfirmation = (val) => {
    let message = val
      ? "Please select bank name"
      : "Amount to be paid must match the amount of plan code chosen";

    Swal.fire({
      position: "bottom",
      icon: "warning",
      title: message,
      showConfirmButton: true,
      background: "var(--primary-color)",
      color: "var(--light-color)",
      confirmButtonColor: "var(--secondary-color)",
    });
  };
  const validationHandler = (val) => {
    let message = val.data.message[0].detail;
    let status = val.data.message[0].status;

    Swal.fire({
      position: "center",
      title: message,
      icon: status === "205" ? "success" : "error",
      showConfirmButton: true,
      background: "var(--primary-color)",
      color: "var(--light-color)",
    });
  };
  const conifrmationReferenceHandler = () => {
    Swal.fire({
      position: "bottom",
      icon: "warning",
      title:
        "There's a problem with your submission. Please check the fields that need your attention",
      showConfirmButton: true,
      background: "var(--primary-color)",
      color: "var(--light-color)",
      confirmButtonColor: "var(--secondary-color)",
    });
  };
  return {
    clientPortal,
    termCondtionPortal,
    pecTermCondtion,
    errorHandler,
    refundPortal,
    dataPrivacy,
    paymentConfirmation,
    validationHandler,
    conifrmationReferenceHandler,
  };
}
