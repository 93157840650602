<template>
  <div class="wrapper">
    <div class="portfolio-header">
      <span class="menu-back card-palette">
        <SettingBack class="icon" @click="routeHandler" />
      </span>
      <h2>Search Clients</h2>
    </div>
    <div class="group-container">
      <input
        class="card-palette"
        type="text"
        name="search"
        placeholder="Search..."
        v-model="searchEngine"
        @input="searchClientHandler"
      />
    </div>
    <div class="group-container">
      <span class="group-title" v-if="itemClients.length">
        <h3>Showing result</h3>
      </span>
      <div v-if="itemClients.length" class="clients">
        <div v-for="(item, index) in itemClients" :key="index">
          <router-link :to="`/admin/auth/portfolio/client/${item.id}`">
            <div class="card card-palette">
              <h5 class="subtitle">Ref #{{ item.referenceNumber }}</h5>
              <div class="action-header">
                <h4>{{ item.membername.toUpperCase() }}</h4>
                <span
                  class="badge card-palette"
                  :class="
                    item.status === 'paid'
                      ? 'paid'
                      : item.status === 'complete'
                      ? 'complete'
                      : 'pending'
                  "
                >
                  <h5>{{ item.status }}</h5>
                </span>
              </div>

              <span class="grid">
                <span class="group-details">
                  <PrincipalSVG
                    v-if="item.relationship === 'SAME' ? true : false"
                  />
                  <DependentSVG v-else />

                  <h5 class="value">
                    {{
                      item.relationship === "SAME" ? "Principal" : "Dependent"
                    }}
                  </h5>
                </span>
                <span class="flex-right">
                  <CashSVG />

                  <h5 class="value">
                    {{ pesoFormat(item.premium) }}
                  </h5>
                </span>
              </span>
              <span class="group-details">
                <LocationSVG />
                <h5 class="value">{{ item.homeaddressOne.toUpperCase() }}</h5>
              </span>
            </div>
          </router-link>
        </div>
      </div>
      <div v-else-if="searchEngine" class="no-transaction">
        <ArchiveData />
        <h4>Sorry, No result found</h4>
        <h6>We couldn't find match for {{ searchEngine }}</h6>
      </div>
      <div v-else class="no-transaction">
        <ArchiveData />
        <h4>What would you like to search for?</h4>
        <h6>Search by entering a member name or school above.</h6>
      </div>
    </div>
  </div>
</template>
<script>
import DependentSVG from "@/components/svgs/dependentSVG.vue";
import PrincipalSVG from "@/components/svgs/principalSVG.vue";
import LocationSVG from "@/components/svgs/locationSVG.vue";
import CashSVG from "@/components/svgs/cashSVG.vue";
import AccountPortalData from "@/data/AccountPortalData";
import SettingBack from "@/components/svgs/settingBackSVG.vue";
import ArchiveData from "@/components/svgs/archiveSVG.vue";
import { inject, ref } from "vue";
export default {
  components: {
    ArchiveData,
    SettingBack,
    CashSVG,
    DependentSVG,
    PrincipalSVG,
    LocationSVG,
  },
  setup() {
    const { authPortfolioClients, itemClients } = AccountPortalData();
    const router = inject("$router");
    const searchEngine = ref(null);
    const routeHandler = () => {
      router.push({ name: "authAgentPortfolio" });
    };
    const searchClientHandler = () => {
      authPortfolioClients(searchEngine.value);
    };
    const pesoFormat = (val) => {
      let modified = val.toLocaleString("en-PH", {
        style: "currency",
        currency: "PHP",
      });
      return `${modified}`;
    };
    return {
      routeHandler,
      searchClientHandler,
      authPortfolioClients,
      pesoFormat,
      searchEngine,
      itemClients,
    };
  },
};
</script>
<style scoped>
.wrapper {
  margin: 40px 20px;
}
.portfolio-header {
  margin-bottom: 25px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}
.portfolio-header h2 {
  color: var(--semi-light-color);
}
.group-container {
  margin: 10px 0px 0px 0px;
}
input[type="text"] {
  height: 45px !important;
  min-width: 100%;
  font-size: 16px !important;
  padding: 0px 10px;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 12px;
  text-transform: none;
  color: var(--light-color);
}
input[type="text"]::placeholder {
  color: var(--semi-light-color);
}
.col-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  height: 75px;
}
.col-paid,
.col-unpaid {
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  gap: 5px;
}
.portfolio {
  min-height: 180px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.portfolio h1 {
  font-size: 54px;
  color: var(--accent-color);
  font-weight: 700;
}
.portfolio h3 {
  margin-top: 30px;
  font-size: 16px;
  color: var(--semi-light-color);
  font-weight: 400;
}
.card {
  margin: 12px 0px;
  min-height: 100px;
  border-radius: 8px;
  padding: 10px;
}
.card h4 {
  padding: 5px 0px;
  color: var(--light-color);
  font-weight: 800;
}
.card .group-details {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
  padding: 0px 10px;
  margin: 6px 0px;
}
.card .group-details .title,
.group-container .group-title h3 {
  color: var(--semi-light-color);
  font-weight: 400;
}
.card .group-details .value {
  color: var(--light-color);
  font-weight: 500;
}

.action-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.flex-right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 5px;
  padding: 0px 10px;
  margin: 6px 0px;
  color: var(--semi-light-color);
  font-weight: 600;
}
.badge {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  border-radius: 9999px;
  font-size: 0.75rem;
  line-height: 1rem;
  color: #ffffff;
  text-transform: uppercase;
}
.paid {
  background-color: rgba(5, 255, 5, 0.473);
}
.pending {
  background-color: rgba(27, 27, 27, 0.473);
}
.complete {
  background-color: rgba(199, 196, 9, 0.911);
}
a {
  text-decoration: none;
}
.no-transaction {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  margin-top: 30px;
  flex-direction: column;
  gap: 5px;
  text-align: center;
}
.no-transaction h4 {
  color: var(--light-color);
  font-weight: 600;
}
.no-transaction h6 {
  color: var(--light-color);
  font-weight: 400;
}
</style>
