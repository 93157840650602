<template>
  <div class="dashboard">
    <aside class="sidebar">
      <div class="logo">Admin Dashboard</div>
      <nav class="nav">
        <ul>
          <li><a href="#">Dashboard</a></li>
          <li><a href="#">POS Transaction</a></li>
          <li><a href="#">Online Transaction</a></li>
          <li><a href="#">Check Transaction</a></li>
          <li><a href="#">Reports</a></li>
        </ul>
      </nav>
    </aside>
    <main class="content">
      <router-view />
    </main>
  </div>
</template>
<style scoped>
.dashboard {
  display: flex;
  min-height: 100vh;
}

.sidebar {
  width: 250px;
  background-color: #343a40;
  color: #ffffff;
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.sidebar .logo {
  font-size: 1.5em;
  text-align: center;
  margin-bottom: 2em;
  font-weight: bold;
}

.nav ul {
  list-style: none;
  padding: 0;
}

.nav ul li {
  margin: 15px 0;
}

.nav ul li a {
  text-decoration: none;
  color: #ffffff;
  font-size: 1.1em;
  transition: color 0.3s ease;
}

.nav ul li a:hover {
  color: #17a2b8;
}

.content {
  flex-grow: 1;
  padding: 20px;
  background-color: #f8f9fa;
}
</style>
