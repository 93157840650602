<template>
  <ManualAgentCode />
</template>
<script>
import ManualAgentCode from "@/components/partials/general/ManualAgentCode.vue";
export default {
  components: {
    ManualAgentCode,
  },
  setup() {},
};
</script>
