<template>
  <div class="wrapper">
    <div class="portfolio-header">
      <span class="menu-back card-palette">
        <SettingBack class="icon" @click="routeHandler" />
      </span>
      <h2>Member's Information</h2>
    </div>
    <div v-if="isRender">
      <div class="card-container">
        <img class="card" :src="require('@/assets/images/ims-vcard.png')" />
        <div class="card-details">
          <h3>{{ itemDetails.iwccardno }}</h3>
          <h4>{{ itemDetails.membername }}</h4>
          <h4>Department of Education</h4>
        </div>
      </div>
      <div
        class="group-container alignment"
        v-if="itemDetails.status === 'complete'"
      >
        <span class="share card-palette">
          <ShareSVG class="icon" @click="copyPasteHandler()" />
        </span>
        <input
          v-model="urlCardLink"
          type="text"
          name="text"
          class="card-palette"
          :class="{ 'focused-class': copied }"
          readonly
        />
      </div>
      <div class="group-container">
        <h3>Policy Details</h3>
        <div class="information card-palette">
          <div class="item-container">
            <ul>
              <li class="title">Reference Number:</li>
              <li class="value">{{ itemDetails.referenceNumber }}</li>
            </ul>
            <ul>
              <li class="title">Card Number:</li>
              <li class="value">
                {{ itemDetails.iwccardno }}
              </li>
            </ul>
            <ul>
              <li class="title">Classification Type:</li>
              <li class="value">
                {{
                  itemDetails.relationship === "SAME"
                    ? "Principal"
                    : "Dependent"
                }}
              </li>
            </ul>
            <ul>
              <li class="title">Premium:</li>
              <li class="value">{{ formatPremium(itemDetails) }}</li>
            </ul>
            <ul>
              <li class="title">Enrolled Date:</li>
              <li class="value">{{ formatDate(itemDetails.createdAt) }}</li>
            </ul>
            <ul>
              <li class="title">Status:</li>
              <li class="value">{{ itemDetails.status }}</li>
            </ul>
            <ul>
              <li class="title">Payment Method:</li>
              <li class="value">{{ itemDetails.paymentmethod }}</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="group-container">
        <h3>Personal Details</h3>
        <div class="information card-palette">
          <div class="item-container">
            <ul v-if="itemDetails.relationship !== 'SAME' ? true : false">
              <li class="title">Payor Name:</li>
              <li class="value">{{ itemDetails.payorname }}</li>
            </ul>
            <ul v-if="itemDetails.relationship !== 'SAME' ? true : false">
              <li class="title">Relationship:</li>
              <li class="value">{{ itemDetails.relationship }}</li>
            </ul>
            <ul>
              <li class="title">Insured Name:</li>
              <li class="value">{{ itemDetails.membername }}</li>
            </ul>
            <ul>
              <li class="title">Birthdate:</li>
              <li class="value">{{ formatDate(itemDetails.birthdate) }}</li>
            </ul>
            <ul>
              <li class="title">Civil Status:</li>
              <li class="value">{{ itemDetails.civilstatus }}</li>
            </ul>
            <ul>
              <li class="title">Contact Number:</li>
              <li class="value">{{ itemDetails.contactnumber }}</li>
            </ul>
            <ul>
              <li class="title">Email Address:</li>
              <li class="value">{{ itemDetails.email }}</li>
            </ul>
            <ul>
              <li class="title">School Name:</li>
              <li class="value">{{ itemDetails.homeaddressOne }}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <app-loading-spin class="spinner" v-else />
  </div>
</template>
<script>
import { useRoute } from "vue-router";
import AccountPortalData from "@/data/AccountPortalData";
import { computed, onMounted, inject, ref } from "vue";
import SettingBack from "@/components/svgs/settingBackSVG.vue";
import ShareSVG from "@/components/svgs/shareSVG.vue";
export default {
  components: {
    SettingBack,
    ShareSVG,
  },
  setup() {
    const { authPortfolioClientsById, clientDetails, isRender } =
      AccountPortalData();
    const route = useRoute();
    const moment = inject("$moment");
    const router = inject("$router");
    const copied = ref(false);

    onMounted(() => {
      authPortfolioClientsById(route.params.id);
    });
    const itemDetails = computed(() => {
      return clientDetails.value;
    });
    const routeHandler = () => {
      router.back();
    };
    const formatPremium = (val) => {
      if (typeof val.premium !== "number") {
        return "N/A";
      }

      let paymentMethod = formatPaymentMethod(val);

      let mod = val.premium.toLocaleString("en-PH", {
        style: "currency",
        currency: "PHP",
      });

      return `${mod} (${paymentMethod})`;
    };
    const formatPaymentMethod = (val) => {
      let abbrev = val.plan_type.PaymentMethod;
      let res;
      if (abbrev === "A") {
        res = "Annual";
      }
      if (abbrev === "M") {
        res = "Monthly";
      }
      if (abbrev === "Q") {
        res = "Quarterly";
      }
      if (abbrev === "S") {
        res = "Semi-annual";
      }
      return res;
    };
    const formatDate = (val) => {
      if (!val) {
        return "Invalid Date";
      }
      return moment(val).format("LL");
    };
    const copyPasteHandler = async () => {
      try {
        await navigator.clipboard.writeText(urlCardLink.value);
        copied.value = true;
        setTimeout(() => {
          copied.value = false;
        }, 3000);
      } catch (err) {
        console.error("Failed to copy: ", err);
      }
    };
    const urlCardLink = computed(() => {
      let cardno = itemDetails.value.iwccardno;
      let url = "https://virtualcard.legacy-apps.ims.com.ph/?cardNo=";
      return `${url}${cardno}`;
    });
    return {
      authPortfolioClientsById,
      urlCardLink,
      clientDetails,
      itemDetails,
      formatPremium,
      formatPaymentMethod,
      formatDate,
      routeHandler,
      copyPasteHandler,
      copied,
      isRender,
    };
  },
};
</script>
<style scoped>
.wrapper {
  margin: 40px 20px;
}
.portfolio-header {
  margin-bottom: 25px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}
.portfolio-header h2 {
  color: var(--accent-color);
}
.card-container {
  display: flex;
  flex-direction: column;
  position: relative;
}
.card {
  height: 100%;
  width: 100%;
}
.card-details {
  position: absolute;
  bottom: 30%;
  left: 10%;
  color: var(--light-color);
  line-height: 22px;
}
.card-details h3,
.card-details h4 {
  font-weight: 400;
  font-family: var(--card-font);
  letter-spacing: 1.8px;
}
.card-details h3 {
  font-size: 1.35em;
}
.card-details h4 {
  font-size: 1.05em;
}
.group-container {
  margin: 10px 0px 0px 0px;
}
.group-container h3 {
  padding: 5px 0px;
  color: var(--semi-light-color);
  font-weight: 600;
  font-size: 16px;
}
.information {
  margin: 12px 0px;
}
.item-container {
  padding: 14px;
}
.information ul {
  display: flex;
  justify-content: space-between;
  font-size: 0.85em;
  margin: 10px 0px;
}
.information ul .title {
  color: var(--semi-light-color);
  font-weight: 400;
}
.information ul .value {
  color: var(--light-color);
  font-weight: 500;
}
.share {
  height: 44px;
  width: 44px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.share .icon {
  height: 26px;
  width: 26px;
}
.alignment {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}
input[type="text"] {
  height: 45px !important;
  min-width: 290px;
  font-size: 12px !important;
  padding: 0px 10px;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 12px;
  text-transform: none;
  color: var(--light-color);
}
.focused-class {
  outline: none !important;
  border: 1px solid var(--secondary-color) !important;
  box-shadow: 0 0 2px var(--semi-light-color) !important;
}
</style>
